export const WORDS = [
  'chode',
  'pussy',
  'titty',
  'dildo',
  'penis',
  'prick',
  'bitch',
  'shaft',
  'willy',
  'whore',
  'bulge',
  'labia',
  'screw',
  'booty',
  'flick',
  'horny',
  'grope',
  'erect',
  'lucky',
  'pound',
  'smash',
  'taste',
  'daddy',
  'zaddy',
  'balls',
  'spank',
  'dirty',
  'nasty',
  'freak',
  'quick',
  'grind',
  'kinky',
  'juicy',
  'loose',
  'tight',
  'flash',
  'slide',
  'semen',
  'vulva',
  'cream',
  'jiggy',
  'jizzy',
  'mount',
  'touch',
  'boner',
  'throb',
  'pulse',
  'booby',
  'sperm',
  'spunk',
  'skeet',
  'spurt',
  'fluid',
  'sweat',
  'stain',
  'slurp',
]
